import { DxTabPanel, DxItem } from 'devextreme-vue/ui/tab-panel';
import { defineComponent, defineAsyncComponent } from 'vue';

export default defineComponent({
  name: 'DocumentosInutilizadosTabs',
  components: {
    DxTabPanel, 
    DxItem,
    Inutilizados: defineAsyncComponent(() => import('./inutilizados/index.vue')),
    Faltantes: defineAsyncComponent(() => import('./faltantes/index.vue'))
  }
})