<template>
  <div>
    <DxTabPanel
      height="100%"
      :show-nav-buttons="true"
      :repaint-changes-only="true"
      :animation-enabled="true"
    >
    <DxItem title="Inutilizados">
      <template #default>
        <Inutilizados/>
      </template>
    </DxItem>
    <DxItem title="Faltantes" :visible="false">
      <template #default>
        <Faltantes/>
      </template>
    </DxItem>
    </DxTabPanel>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>